<template>
  <div class="list">
    <div class="list-item">
      <div class="item-top green">
        <div class="flex-between-center">
          <div class="item-top-left">
            <div class="fs18 mb12">处方审核</div>
            <div class="fs12">更便捷的处方审核</div>
          </div>
          <div class="item-top-right">
            <van-button round size="mini" plain @click="toListPage('2')">立即进入</van-button>
          </div>
        </div>
      </div>
      <div class="item-bottom flex-around-center">
        <div class="tac" @click="toListPage('2')">
          <div class="dark fs18 mb8">{{ needAudit }}</div>
          <div class="normal">待审核</div>
        </div>
        <div class="tac" @click="toListPage('3')">
          <div class="dark fs18 mb8">{{ refused }}</div>
          <div class="normal">审核驳回</div>
        </div>
        <div class="tac" @click="toListPage('4')">
          <div class="dark fs18 mb8">{{ agree }}</div>
          <div class="normal">审核通过</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      needAudit: 0,
      agree: 0,
      refused: 0,
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$post('/api/blade-consult/drugDoctorWork/getNeedAuditRecipeCount')
        .then(({ data }) => {
          this.needAudit = data.needAudit;
          this.refused = data.refused;
          this.agree = data.agree;
        })
    },
    toListPage(recipeStatus) {
      this.$router.push({
        path: '/yaoshi/list',
        query: {
          recipeStatus,
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.list {
  .list-item {
    margin-bottom: 12px;
    .item-top {
      padding: 18px 12px;
      color: #fff;
      &.green {
        background-color: #1bc3a2;
      }
      &.blue {
        background-color: #4885e0;
      }
    }
    .item-bottom {
      background-color: #fff;
      padding: 12px 0;
      .bottom-left,
      .bottom-right {
        text-align: center;
      }
    }
  }
}
</style>
